import "core-js/modules/es6.object.keys.js";
import "core-js/modules/es6.symbol.js";
import "core-js/modules/es6.array.filter.js";
import "core-js/modules/es6.object.get-own-property-descriptor.js";
import "core-js/modules/es7.object.get-own-property-descriptors.js";
import _defineProperty from "/var/www/html/gui/node_modules/@babel/runtime-corejs2/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { list } from "../../utils/vuexer";
import MailFilterForm from "./Filter";
import { DataFilter, ActionCell, Breadcrumb, Toolbar } from "../../common/components";
import date from 'quasar/src/utils/date.js';;
import ListMixin from "../../common/mixins/ListMixin";
import TestMail from "./TestMail";
var servicePrefix = 'Mail';

var _list = list(servicePrefix),
    getters = _list.getters,
    actions = _list.actions;

import fetch from "../../utils/fetch";
export default {
  name: 'MailList',
  servicePrefix: servicePrefix,
  mixins: [ListMixin],
  components: {
    TestMail: TestMail,
    MailFilterForm: MailFilterForm,
    DataFilter: DataFilter,
    Breadcrumb: Breadcrumb,
    ActionCell: ActionCell,
    Toolbar: Toolbar
  },
  data: function data() {
    return {
      showTestMail: false,
      item: null,
      pagination: {
        sortBy: 'createdAt',
        descending: true
      },
      columns: [// { name: 'template', align: 'center', field: row => row.template.libelle, label: this.$t('template') },
      {
        name: 'createdAt',
        align: 'center',
        field: 'createdAt',
        label: this.$t('createdAt'),
        format: function format(val) {
          return date.formatDate(val, 'DD/MM/YYYY');
        }
      }, {
        name: 'subject',
        align: 'center',
        field: 'subject',
        label: this.$t('subject')
      }, {
        name: 'dateEnvoi',
        align: 'center',
        field: 'dateEnvoi',
        label: this.$t('dateEnvoi'),
        format: function format(val) {
          return date.formatDate(val, 'DD/MM/YYYY');
        }
      }, {
        name: 'status',
        align: 'center',
        field: 'status',
        label: this.$t('status')
      }, {
        name: 'percent',
        label: this.$t('percent')
      }, {
        name: 'nb_destinataires',
        align: 'center',
        field: function field(row) {
          return row.nbDestinataires;
        },
        label: this.$t('destinataires')
      }, {
        name: 'nb_errors',
        align: 'center',
        field: function field(row) {
          return row.totalError;
        },
        label: this.$t('errors')
      }, {
        name: 'nb_unsubscribe',
        align: 'center',
        field: function field(row) {
          return row.totalUnsubscribed;
        },
        label: this.$t('unsubscribe')
      }, {
        name: 'btn',
        label: ''
      }, {
        name: 'action'
      }]
    };
  },
  computed: getters,
  methods: _objectSpread({
    tester: function tester(item) {
      this.item = item;
      this.showTestMail = true;
    },
    sendTest: function sendTest(email) {
      this.showTestMail = false;
      fetch('../mail/' + this.item.id + '/prepare-send-test', {
        params: {
          email: email
        }
      });
    },
    send: function send(item) {
      var _this = this;

      fetch('../mail/' + item.id + '/prepare-send-mail').then(function () {
        _this.$router.go();
      });
    },
    copy: function copy(item) {
      var _this2 = this;

      fetch('../mail/' + item.id + '/copyMail').then(function () {
        _this2.$router.go();
      });
    }
  }, actions)
};