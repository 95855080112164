import _defineProperty from "/var/www/html/gui/node_modules/@babel/runtime-corejs2/helpers/defineProperty";
import "core-js/modules/es6.object.assign.js";
export default (function (initState, types) {
  var _ref;

  return _ref = {}, _defineProperty(_ref, types.RESET, function (state) {
    Object.assign(state, initState);
  }), _defineProperty(_ref, types.SET_ERROR, function (state, error) {
    Object.assign(state, {
      error: error
    });
  }), _defineProperty(_ref, types.TOGGLE_LOADING, function (state) {
    Object.assign(state, {
      isLoading: !state.isLoading
    });
  }), _defineProperty(_ref, types.SET_RETRIEVED, function (state, retrieved) {
    Object.assign(state, {
      retrieved: retrieved
    });
  }), _defineProperty(_ref, types.SET_UPDATED, function (state, updated) {
    Object.assign(state, {
      error: '',
      updated: updated,
      violations: null
    });
  }), _defineProperty(_ref, types.SET_VIOLATIONS, function (state, violations) {
    Object.assign(state, {
      violations: violations
    });
  }), _ref;
});