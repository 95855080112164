//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'Breadcrumb',
  props: {
    values: {
      type: Array,
      required: true
    },
    item: {
      type: Object,
      required: false
    }
  }
};