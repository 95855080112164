import "core-js/modules/es6.object.keys.js";
import "core-js/modules/es6.symbol.js";
import "core-js/modules/es6.array.filter.js";
import "core-js/modules/es6.object.get-own-property-descriptor.js";
import "core-js/modules/es7.object.get-own-property-descriptors.js";
import _defineProperty from "/var/www/html/gui/node_modules/@babel/runtime-corejs2/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { list } from "../../utils/vuexer";
import MailTemplateFilterForm from "./Filter";
import { DataFilter, ActionCell, Breadcrumb, Toolbar } from "../../common/components";
import fetch from "../../utils/fetch";
import ListMixin from "../../common/mixins/ListMixin";
var servicePrefix = 'MailTemplate';

var _list = list(servicePrefix),
    getters = _list.getters,
    actions = _list.actions;

export default {
  name: 'MailTemplateList',
  servicePrefix: servicePrefix,
  mixins: [ListMixin],
  components: {
    MailTemplateFilterForm: MailTemplateFilterForm,
    DataFilter: DataFilter,
    Breadcrumb: Breadcrumb,
    ActionCell: ActionCell,
    Toolbar: Toolbar
  },
  data: function data() {
    return {
      item: null,
      columns: [{
        name: 'code',
        field: 'code',
        label: this.$t('code')
      }, {
        name: 'libelle',
        field: 'libelle',
        label: this.$t('libelle')
      }, {
        name: 'subject',
        field: 'subject',
        label: this.$t('subject')
      }, {
        name: 'senderName',
        field: 'senderName',
        label: this.$t('senderName')
      }, {
        name: 'senderEmail',
        field: 'senderEmail',
        label: this.$t('senderEmail')
      }, {
        name: 'btn',
        label: ''
      }, {
        name: 'action'
      }]
    };
  },
  computed: getters,
  methods: _objectSpread({
    copy: function copy(item) {
      var _this = this;

      fetch('../mail/template/' + item.id + '/copyTemplate').then(function () {
        _this.$router.go();
      });
    }
  }, actions)
};