import "core-js/modules/es6.function.name.js";
import { mapActions, mapGetters, mapMutations, createNamespacedHelpers } from 'vuex';
export var create = function create(module) {
  var lowmod = module.toLowerCase();

  var _createNamespacedHelp = createNamespacedHelpers("".concat(lowmod, "/create")),
      mapGetters = _createNamespacedHelp.mapGetters,
      mapActions = _createNamespacedHelp.mapActions;

  var getters = mapGetters(['error', 'isLoading', 'created', 'violations']);
  var actions = mapActions(['create']);
  return {
    getters: getters,
    actions: actions
  };
};
export var list = function list(module) {
  var lowmod = module.toLowerCase();
  var getters = mapGetters({
    deletedItem: "".concat(lowmod, "/del/deleted"),
    error: "".concat(lowmod, "/list/error"),
    items: "".concat(lowmod, "/list/items"),
    isLoading: "".concat(lowmod, "/list/isLoading"),
    view: "".concat(lowmod, "/list/view"),
    totalItems: "".concat(lowmod, "/list/totalItems")
  });
  var actions = mapActions({
    getPage: "".concat(lowmod, "/list/getItems"),
    deleteItem: "".concat(lowmod, "/del/del")
  });
  return {
    getters: getters,
    actions: actions
  };
};
export var show = function show(module) {
  var lowmod = module.toLowerCase();
  var getters = mapGetters({
    deleteError: "".concat(lowmod, "/del/error"),
    error: "".concat(lowmod, "/show/error"),
    isLoading: "".concat(lowmod, "/show/isLoading"),
    item: "".concat(lowmod, "/show/retrieved")
  });
  var actions = mapActions({
    del: "".concat(lowmod, "/del/del"),
    reset: "".concat(lowmod, "/show/reset"),
    retrieve: "".concat(lowmod, "/show/retrieve")
  });
  return {
    getters: getters,
    actions: actions
  };
};
export var update = function update(module) {
  var lowmod = module.toLowerCase();
  var getters = mapGetters({
    isLoading: "".concat(lowmod, "/update/isLoading"),
    error: "".concat(lowmod, "/update/error"),
    deleteError: "".concat(lowmod, "/del/error"),
    deleteLoading: "".concat(lowmod, "/del/isLoading"),
    deleted: "".concat(lowmod, "/del/deleted"),
    retrieved: "".concat(lowmod, "/update/retrieved"),
    updated: "".concat(lowmod, "/update/updated"),
    violations: "".concat(lowmod, "/update/violations")
  });
  var actions = mapActions({
    createReset: "".concat(lowmod, "/create/reset"),
    deleteItem: "".concat(lowmod, "/del/del"),
    delReset: "".concat(lowmod, "/del/reset"),
    retrieve: "".concat(lowmod, "/update/retrieve"),
    updateReset: "".concat(lowmod, "/update/reset"),
    update: "".concat(lowmod, "/update/update")
  });
  return {
    getters: getters,
    actions: actions
  };
};
export var form = function form(modules) {
  var getters = {},
      actions = {},
      mutations = {};
  modules.forEach(function (_ref) {
    var name = _ref.name,
        module = _ref.module;
    var lowmod = module.toLowerCase();
    getters["".concat(name, "SelectItems")] = "".concat(lowmod, "/list/selectItems");
    getters["".concat(name, "SelectItemsTemplate")] = "".concat(lowmod, "/list/selectItemsTemplate");
    actions["".concat(name, "GetSelectItems")] = "".concat(lowmod, "/list/getSelectItems");
    mutations["".concat(name, "SetSelectItemsTemplate")] = "".concat(lowmod, "/list/").concat(module.toUpperCase(), "_LIST_SET_SELECT_ITEMS_TEMPLATE");
  });
  return {
    getters: mapGetters(getters),
    actions: mapActions(actions),
    mutations: mapMutations(mutations)
  };
};