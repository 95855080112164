import Vue from 'vue';
import Vuex from 'vuex';
import mail from "./modules/mail/";
import mailtemplate from "./modules/mailtemplate/";
import destinataire from "./modules/destinataire/";
import unsubscribeemail from "./modules/unsubscribeemail";
import emailerror from "./modules/emailerror";
Vue.use(Vuex);
/*
 * If not building with SSR mode, you can
 * directly export the Store instantiation;
 *
 * The function below can be async too; either use
 * async/await or return a Promise which resolves
 * with the Store instance.
 */

export default function ()
/* { ssrContext } */
{
  var Store = new Vuex.Store({
    modules: {
      mail: mail,
      mailtemplate: mailtemplate,
      destinataire: destinataire,
      unsubscribeemail: unsubscribeemail,
      emailerror: emailerror
    },
    // enable strict mode (adds overhead!)
    // for dev mode only
    strict: process.env.DEV
  });
  return Store;
}