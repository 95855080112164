var error = function error(state) {
  return state.error;
};

var isLoading = function isLoading(state) {
  return state.isLoading;
};

var items = function items(state) {
  return state.items;
};

var selectItems = function selectItems(state) {
  return state.selectItems;
};

var selectItemsTemplate = function selectItemsTemplate(state) {
  return state.selectItemsTemplate;
};

var view = function view(state) {
  return state.view;
};

var totalItems = function totalItems(state) {
  return state.totalItems;
};

export { error, isLoading, items, selectItems, selectItemsTemplate, view, totalItems };