export default (function () {
  return {
    error: '',
    isLoading: false,
    items: [],
    view: [],
    totalItems: 10,
    selectItems: null,
    selectItemsTemplate: ''
  };
});