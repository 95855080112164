import fetch from "../../../utils/fetch";
export var delCommon = function delCommon(_ref, item, _ref2) {
  var commit = _ref.commit;
  var types = _ref2.types;
  commit(types.TOGGLE_LOADING);
  return fetch(item['@id'], {
    method: 'DELETE'
  }).then(function () {
    commit(types.TOGGLE_LOADING);
    commit(types.SET_DELETED, item);
  }).catch(function (e) {
    commit(types.TOGGLE_LOADING);
    commit(types.SET_ERROR, e.message);
  });
};
export var resetCommon = function resetCommon(_ref3, _ref4) {
  var commit = _ref3.commit;
  var types = _ref4.types;
  commit(types.RESET);
};