import fetch from "../../../utils/fetch";
export var getItemsCommon = function getItemsCommon(_ref, _ref2, _ref3) {
  var commit = _ref.commit;
  var page = _ref2.page,
      params = _ref2.params;
  var types = _ref3.types,
      hydraPrefix = _ref3.hydraPrefix;
  commit(types.TOGGLE_LOADING);
  return fetch(page, {
    params: params
  }).then(function (response) {
    return response.json();
  }).then(function (data) {
    commit(types.TOGGLE_LOADING);
    commit(types.SET_ITEMS, data["".concat(hydraPrefix, "member")]);
    commit(types.SET_VIEW, data["".concat(hydraPrefix, "view")]);
    commit(types.SET_TOTALITEMS, data["".concat(hydraPrefix, "totalItems")]);
  }).catch(function (e) {
    commit(types.TOGGLE_LOADING);
    commit(types.SET_ERROR, e.message);
  });
};
export var getSelectItemsCommon = function getSelectItemsCommon(_ref4, _ref5, _ref6) {
  var commit = _ref4.commit;
  var page = _ref5.page,
      params = _ref5.params;
  var types = _ref6.types,
      hydraPrefix = _ref6.hydraPrefix;
  commit(types.TOGGLE_LOADING);
  return fetch(page, {
    params: params
  }).then(function (response) {
    return response.json();
  }).then(function (data) {
    commit(types.TOGGLE_LOADING);
    commit(types.SET_SELECT_ITEMS, data["".concat(hydraPrefix, "member")]);
  }).catch(function (e) {
    commit(types.TOGGLE_LOADING);
    commit(types.SET_ERROR, e.message);
  });
};