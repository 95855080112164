import "core-js/modules/es6.object.keys.js";
import "core-js/modules/es6.symbol.js";
import "core-js/modules/es6.array.filter.js";
import "core-js/modules/es6.object.get-own-property-descriptor.js";
import "core-js/modules/es7.object.get-own-property-descriptors.js";
import _defineProperty from "/var/www/html/gui/node_modules/@babel/runtime-corejs2/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import "core-js/modules/es6.function.name.js";
import { error as _error, success } from "../../utils/notify";
export default {
  data: function data() {
    return {
      item: {}
    };
  },
  created: function created() {
    this.retrieve(decodeURIComponent(this.$route.params.id));
  },
  beforeDestroy: function beforeDestroy() {
    this.reset();
  },
  watch: {
    deleted: function deleted(_deleted) {
      if (!_deleted) {
        return;
      }

      this.$router.push({
        name: "".concat(this.$options.servicePrefix, "List")
      }).catch(function () {});
    },
    error: function error(message) {
      message && _error(message, this.$t('Close'));
    },
    deleteError: function deleteError(message) {
      message && _error(message, this.$t('Close'));
    },
    updated: function updated(val) {
      success("".concat(val.name, " ").concat(this.$t('Updated'), "."), this.$t('Close'));
      this.$router.push({
        name: "".concat(this.$options.servicePrefix, "List")
      }).catch(function () {});
    },
    retrieved: function retrieved(val) {
      this.item = _objectSpread({}, val);
    }
  },
  methods: {
    del: function del() {
      var _this = this;

      this.deleteItem(this.retrieved).then(function () {
        return _this.$router.push({
          name: "".concat(_this.$options.servicePrefix, "List")
        });
      });
    },
    reset: function reset() {
      this.updateReset();
      this.delReset();
      this.createReset();
    },
    onSendForm: function onSendForm() {
      var _this2 = this;

      this.$refs.updateForm.$children[0].validate().then(function (success) {
        if (success) {
          _this2.update(_this2.item);
        }
      });
    },
    resetForm: function resetForm() {
      this.item = _objectSpread({}, this.retrieved);
    }
  }
};