//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import ConfirmDelete from "./ConfirmDelete";
export default {
  name: 'ActionCell',
  components: {
    ConfirmDelete: ConfirmDelete
  },
  data: function data() {
    return {
      confirmDelete: false
    };
  },
  props: {
    handleShow: {
      type: Function,
      required: false
    },
    handleEdit: {
      type: Function,
      required: false
    },
    handleDelete: {
      type: Function,
      required: false
    },
    canEdit: {
      type: Boolean
    }
  }
};