//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { list } from "../../utils/vuexer";
import { ActionCell, Breadcrumb, Toolbar } from "../../common/components";
import ListMixin from "../../common/mixins/ListMixin";
import date from 'quasar/src/utils/date.js';;
var servicePrefix = 'EmailError';

var _list = list(servicePrefix),
    getters = _list.getters,
    actions = _list.actions;

export default {
  name: 'EmailErrorList',
  servicePrefix: servicePrefix,
  mixins: [ListMixin],
  components: {
    Breadcrumb: Breadcrumb,
    ActionCell: ActionCell,
    Toolbar: Toolbar
  },
  data: function data() {
    var _this = this;

    return {
      columns: [{
        name: 'email',
        field: 'email',
        label: this.$t('email'),
        align: 'center'
      }, {
        name: 'nbError',
        field: 'nbError',
        label: this.$t('nbRetry'),
        format: function format(val) {
          return _this.$n(val);
        },
        align: 'center'
      }, {
        name: 'dateError',
        field: 'dateError',
        label: this.$t('dateError'),
        format: function format(val) {
          return date.formatDate(val, 'DD/MM/YYYY');
        },
        align: 'center'
      }, {
        name: 'action'
      }]
    };
  },
  computed: getters,
  methods: actions
};