import "core-js/modules/es6.object.to-string.js";
import "core-js/modules/es6.array.iterator.js";
import "core-js/modules/web.dom.iterable.js";
import SubmissionError from "../../../error/SubmissionError";
import fetch from "../../../utils/fetch";
export var createCommon = function createCommon(_ref, _ref2, _ref3) {
  var commit = _ref.commit;
  var page = _ref2.page,
      values = _ref2.values;
  var types = _ref3.types;
  commit(types.SET_ERROR, '');
  commit(types.TOGGLE_LOADING);
  return fetch(page, {
    method: 'POST',
    body: JSON.stringify(values)
  }).then(function (response) {
    commit(types.TOGGLE_LOADING);
    return response.json();
  }).then(function (data) {
    commit(types.SET_CREATED, data);
  }).catch(function (e) {
    commit(types.TOGGLE_LOADING);

    if (e instanceof SubmissionError) {
      commit(types.SET_VIOLATIONS, e.errors); // eslint-disable-next-line

      commit(types.SET_ERROR, e.errors._error);
      return;
    }

    commit(types.SET_ERROR, e.message);
  });
};
export var resetCommon = function resetCommon(_ref4, _ref5) {
  var commit = _ref4.commit;
  var types = _ref5.types;
  commit(types.RESET);
};