export var deleted = function deleted(state) {
  return state.deleted;
};
export var error = function error(state) {
  return state.error;
};
export var isLoading = function isLoading(state) {
  return state.isLoading;
};
export default {
  error: error,
  isLoading: isLoading,
  deleted: deleted
};