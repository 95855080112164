import { extractDate } from "../../utils/dates";
export default {
  created: function created() {
    this.retrieve(decodeURIComponent(this.$route.params.id));
  },
  beforeDestroy: function beforeDestroy() {
    this.reset();
  },
  watch: {
    error: function error(message) {
      message && this.$q.notify({
        message: message,
        color: 'red',
        icon: 'error',
        closeBtn: this.$t('Close')
      });
    },
    deleteError: function deleteError(message) {
      message && this.$q.notify({
        message: message,
        color: 'red',
        icon: 'error',
        closeBtn: this.$t('Close')
      });
    }
  },
  methods: {
    formatDateTime: function formatDateTime(val, format) {
      return val ? this.$d(extractDate(val), format) : '';
    },
    deleteItem: function deleteItem() {
      var _this = this;

      this.deleteItem(this.item).then(function () {
        return _this.$router.push({
          name: "".concat(_this.$options.servicePrefix, "List")
        });
      });
    }
  }
};