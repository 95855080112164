export var error = function error(state) {
  return state.error;
};
export var isLoading = function isLoading(state) {
  return state.isLoading;
};
export var retrieved = function retrieved(state) {
  return state.retrieved;
};
export var updated = function updated(state) {
  return state.updated;
};
export var violations = function violations(state) {
  return state.violations;
};
export default {
  error: error,
  isLoading: isLoading,
  retrieved: retrieved,
  updated: updated,
  violations: violations
};