import "core-js/modules/es6.object.to-string.js";
import "core-js/modules/es6.array.iterator.js";
import "core-js/modules/web.dom.iterable.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  props: {
    values: {
      type: Object,
      required: true
    }
  },
  data: function data() {
    return {};
  },
  computed: {
    // eslint-disable-next-line
    item: function item() {
      return this.initialValues || this.values;
    }
  },
  methods: {}
};