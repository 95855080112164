// This is just an example,
// so you can safely delete all default props below
export default {
  failed: 'L\'action n\'a pas pu s\'effectuer',
  success: 'L\'action s\'est exécutée avec succès',
  Submit: 'Valider',
  Reset: 'Annuler',
  Delete: 'Effacer',
  Filters: 'Filtres',
  Filter: 'Filtrer',
  'Records per page:': 'Nb / pages:',
  Add: 'Ajouter',
  template: 'Template',
  data: 'Data',
  status: 'Statut',
  totalToSend: 'Total à envoyer',
  totalSended: 'Total envoyé',
  destinataires: 'Destinataire(s)',
  destinataire: 'Destinataire',
  email: 'Email',
  code: 'Code',
  libelle: 'Libellé',
  subject: 'Sujet',
  senderName: 'Expéditeur',
  senderEmail: 'Email Expéditeur',
  texte: 'Texte',
  mjml: 'MJML',
  mailToSend: 'Mail(s) à envoyer',
  mailsSent: 'Mail(s) envoyés',
  progressOfMailsSent: 'Progression des mails envoyés',
  progress: 'Progression',
  general: 'Général',
  content: 'Contenu',
  percent: 'Pourcentage d\'emails envoyés',
  stats: 'Statistiques',
  dateUnsuscribe: 'Date de désinscription',
  nbRetry: 'Nombre d\'essai',
  to: 'A',
  mail: 'Mail de référence',
  mailsToSend: 'Mail(s) à envoyer',
  dateEnvoi: "Date d'envoi",
  createdAt: 'Créé le',
  toName: 'Nom',
  toEmail: 'Email',
  menuHome: 'Accueil',
  menuMails: 'Mails',
  menuModeles: 'Modèles',
  menuDestinataires: 'Destinataires',
  menuDesinscriptions: 'Désinscriptions',
  cancel: 'Annuler',
  send: 'Envoyer',
  UnsubscribeEmailList: 'Liste Désinscriptions',
  EmailErrorList: 'Liste des Mails en Erreur',
  MailList: 'Liste des Mails',
  MailTemplateList: 'Liste des Modèles',
  DestinataireList: 'Liste des Destinataires',
  MailTemplateShow: 'Modèle',
  DestinataireShow: 'Destinataire',
  MailShow: 'Mail',
  UnsubscribeEmailShow: 'Désinscription',
  EmailErrorShow: 'Mail en Erreur',
  delete: 'Supprimer',
  confirmDelete: 'Êtes-vous certain de vouloir supprimer cet élément ?',
  MailUpdate: 'Editer le mail ',
  MailTemplateUpdate: 'Editer le modèle',
  DestinataireUpdate: 'Editer le destinataire',
  UnsubscribeEmailUpdate: 'Editer la désinscription',
  EmailErrorUpdate: 'Editer un mail en erreur',
  UnsubscribeEmailCreate: 'Créer une désinscription',
  EmailErrorCreate: 'Créer un mail en erreur',
  DestinataireCreate: 'Créer un destinataire',
  MailTemplateCreate: 'Créer un modèle',
  aboutHermes: 'A propos d\'Hermes',
  Updated: 'mis à jour',
  Close: 'Fermer',
  Deleted: 'L\'élément a été supprimé',
  totalUnsubscribed: 'Total de destinataires désinscrits',
  mailsUnsubscribed: 'Mail(s) désinscrits',
  totalError: 'Total des mails en erreur',
  mailsError: 'Mail(s) en erreur',
  attachments: 'Pièce(s) jointe(s) déjà présente(s)',
  balisesMail: 'Liste des balises disponibles dans ce mail ',
  errors: 'Erreur(s)',
  unsubscribe: 'Désinscription(s)',
  html: 'HTML',
  openingDate: 'Date d\'ouverture',
  listBalisesDest: 'Liste des balises destinataires',
  listBalisesOther: 'Liste des balises diverses',
  openRate: 'Taux d\'ouverture',
  unsubscriptions: 'Ne pas envoyer aux mails désinscrits',
  doNotSend: 'Ne pas envoyer',
  menuEmailsError: 'Emails en erreur',
  dateError: 'Date de l\'erreur',
  date: 'Date',
  content_message: 'Contenu du message'
};