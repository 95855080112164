import _defineProperty from "/var/www/html/gui/node_modules/@babel/runtime-corejs2/helpers/defineProperty";
import "core-js/modules/es6.object.assign.js";
export default (function (initState, types) {
  var _ref;

  return _ref = {}, _defineProperty(_ref, types.RESET, function (state) {
    Object.assign(state, initState);
  }), _defineProperty(_ref, types.SET_ERROR, function (state, error) {
    Object.assign(state, {
      error: error
    });
  }), _defineProperty(_ref, types.SET_ITEMS, function (state, items) {
    Object.assign(state, {
      items: items
    });
  }), _defineProperty(_ref, types.SET_SELECT_ITEMS, function (state, selectItems) {
    Object.assign(state, {
      selectItems: selectItems
    });
  }), _defineProperty(_ref, types.SET_SELECT_ITEMS_TEMPLATE, function (state, selectItemsTemplate) {
    Object.assign(state, {
      selectItemsTemplate: selectItemsTemplate
    });
  }), _defineProperty(_ref, types.TOGGLE_LOADING, function (state) {
    Object.assign(state, {
      isLoading: !state.isLoading
    });
  }), _defineProperty(_ref, types.SET_VIEW, function (state, view) {
    Object.assign(state, {
      view: view
    });
  }), _defineProperty(_ref, types.SET_TOTALITEMS, function (state, totalItems) {
    Object.assign(state, {
      totalItems: totalItems
    });
  }), _ref;
});