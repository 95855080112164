import "core-js/modules/es6.object.to-string.js";
import "core-js/modules/es6.regexp.to-string.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import date from 'quasar/src/utils/date.js';;

var localISOString = function localISOString() {
  var d = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : new Date();

  var pad = function pad(n) {
    return n < 10 ? '0' + n : n;
  },
      tz = d.getTimezoneOffset(),
      // mins
  tzs = (tz > 0 ? '-' : '+') + pad(parseInt(Math.abs(tz / 60))) + ':00'; // if (tz % 60 !== 0) tzs += pad(Math.abs(tz % 60))
  // if (tz === 0) {
  // Zulu time == UTC
  //  tzs = 'Z'
  // }


  return d.getFullYear() + '-' + pad(d.getMonth() + 1) + '-' + pad(d.getDate()) + 'T' + pad(d.getHours()) + ':' + pad(d.getMinutes()) + ':' + pad(d.getSeconds()) + tzs;
};

export default {
  name: 'InputDate',
  props: {
    value: String,
    set: Function,
    kind: {
      type: String,
      default: 'datetime'
    }
  },
  data: function data() {
    return {
      isValid: true
    };
  },
  computed: {
    mask: {
      get: function get() {
        switch (this.kind) {
          case 'date':
            return 'YYYY/MM/DD';

          case 'time':
            return 'HH:mm';

          case 'datetime':
          default:
            return 'YYYY/MM/DD HH:mm';
        }
      }
    },
    inputModel: {
      get: function get() {
        switch (this.kind) {
          case 'date':
            return this.Model ? this.$d(new Date(this.Model), 'short') : '';

          case 'time':
            return this.Model;

          case 'datetime':
          default:
            return this.Model ? this.$d(new Date(this.Model), 'long') : '';
        }
      },
      set: function set(value) {
        if (!value) {
          return;
        }

        this.Model = null;
        this.isValid = true;

        if (value.length === 10) {
          var val = value.substr(6, 4) + '/' + value.substr(3, 2) + '/' + value.substr(0, 2);

          if (new Date(val).toString() === 'Invalid Date') {
            this.isValid = false;
          } else {
            this.isValid = true;
            console.debug(val);
            this.Model = val;
          }
        }
      }
    },
    calendarModel: {
      get: function get() {
        return this.Model ? date.formatDate(new Date(this.Model), this.mask) : '';
      },
      set: function set(val) {
        var value = val;

        if (this.kind === 'datetime') {
          localISOString(new Date(val));
        }

        this.$refs.qDateProxy.hide();
        this.$refs.qTimeProxy.hide();
        this.Model = value;
        this.isValid = true;
      }
    },
    Model: {
      get: function get() {
        return this.value;
      },
      set: function set(value) {
        this.set(value);
      }
    }
  }
};