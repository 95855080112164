//
//
//
//
//
//
export default {
  props: {
    showing: {
      type: Boolean,
      required: true
    }
  }
};