import "core-js/modules/es6.object.keys.js";
import "core-js/modules/es6.symbol.js";
import "core-js/modules/es6.array.filter.js";
import "core-js/modules/es6.object.get-own-property-descriptor.js";
import "core-js/modules/es7.object.get-own-property-descriptors.js";
import _defineProperty from "/var/www/html/gui/node_modules/@babel/runtime-corejs2/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import { types } from "./mutation_types";
import { getItemsCommon, getSelectItemsCommon } from "../../../../common/store/list/actions";
var hydraPrefix = 'hydra:';
export var getItems = function getItems(context, options) {
  return getItemsCommon(context, _objectSpread(_objectSpread({}, {
    page: 'unsubscribe_emails',
    params: {}
  }), options), {
    types: types,
    hydraPrefix: hydraPrefix
  });
};
export var getSelectItems = function getSelectItems(context, options) {
  return getSelectItemsCommon(context, _objectSpread(_objectSpread({}, {
    page: 'unsubscribe_emails',
    params: {
      properties: ['id', 'name']
    }
  }), options), {
    types: types,
    hydraPrefix: hydraPrefix
  });
};