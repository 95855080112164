export var created = function created(state) {
  return state.created;
};
export var error = function error(state) {
  return state.error;
};
export var isLoading = function isLoading(state) {
  return state.isLoading;
};
export var violations = function violations(state) {
  return state.violations;
};
export default {
  error: error,
  isLoading: isLoading,
  created: created,
  violations: violations
};