//
//
//
//
//
//
//
//
//
//
import { update } from "../../utils/vuexer";
import DestinataireForm from "./Form.vue";
import { Breadcrumb, Toolbar, Loading } from "../../common/components";
import UpdateMixin from "../../common/mixins/UpdateMixin";
var servicePrefix = 'Destinataire';

var _update = update(servicePrefix),
    getters = _update.getters,
    actions = _update.actions;

export default {
  name: 'DestinataireUpdate',
  servicePrefix: servicePrefix,
  mixins: [UpdateMixin],
  components: {
    DestinataireForm: DestinataireForm,
    Breadcrumb: Breadcrumb,
    Toolbar: Toolbar,
    Loading: Loading
  },
  computed: getters,
  methods: actions
};