//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'ConfirmDelete',
  props: {
    show: {
      type: Boolean,
      required: true
    },
    handleDelete: {
      type: Function,
      required: true
    },
    handleCancel: {
      type: Function,
      required: false
    }
  }
};