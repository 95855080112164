import { types } from "./mutation_types";
import { resetCommon, retrieveCommon, updateCommon } from "../../../../common/store/update/actions";
export var reset = function reset(context) {
  resetCommon(context, {
    types: types
  });
};
export var retrieve = function retrieve(context, id) {
  return retrieveCommon(context, id, {
    types: types
  });
};
export var update = function update(context, values) {
  return updateCommon(context, values, {
    types: types
  });
};