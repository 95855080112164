//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import ConfirmDelete from "./ConfirmDelete";
export default {
  name: 'Toolbar',
  components: {
    ConfirmDelete: ConfirmDelete
  },
  data: function data() {
    return {
      confirmDelete: false
    };
  },
  props: {
    handleSubmit: {
      type: Function,
      required: false
    },
    handleReset: {
      type: Function,
      required: false
    },
    handleDelete: {
      type: Function,
      required: false
    },
    handleAdd: {
      type: Function,
      required: false
    }
  },
  methods: {
    addItem: function addItem() {
      if (this.handleAdd) {
        this.handleAdd();
      }
    },
    submitItem: function submitItem() {
      if (this.handleSubmit) {
        this.handleSubmit();
      }
    },
    resetItem: function resetItem() {
      if (this.handleReset) {
        this.handleReset();
      }
    }
  }
};