//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'DataFilter',
  props: {
    handleReset: {
      type: Function,
      required: true
    },
    handleFilter: {
      type: Function,
      required: true
    }
  },
  data: function data() {
    return {
      filtersExpanded: false
    };
  }
};