//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { list } from "../../utils/vuexer";
import DestinataireFilterForm from "./Filter";
import { DataFilter, ActionCell, Breadcrumb, Toolbar } from "../../common/components";
import ListMixin from "../../common/mixins/ListMixin";
import date from 'quasar/src/utils/date.js';;
var servicePrefix = 'Destinataire';

var _list = list(servicePrefix),
    getters = _list.getters,
    actions = _list.actions;

export default {
  name: 'DestinataireList',
  servicePrefix: servicePrefix,
  mixins: [ListMixin],
  components: {
    Breadcrumb: Breadcrumb,
    ActionCell: ActionCell,
    Toolbar: Toolbar,
    DestinataireFilterForm: DestinataireFilterForm,
    DataFilter: DataFilter
  },
  data: function data() {
    var _this = this;

    return {
      columns: [{
        name: 'mail',
        field: function field(row) {
          return row.mail;
        },
        label: this.$t('mail'),
        align: 'center'
      }, {
        name: 'to',
        field: function field(row) {
          return row.toName;
        },
        label: this.$t('to'),
        align: 'center'
      }, {
        name: 'email',
        field: function field(row) {
          return row.toEmail;
        },
        label: this.$t('email'),
        align: 'center'
      }, {
        name: 'status',
        field: 'status',
        label: this.$t('status'),
        align: 'center'
      }, {
        name: 'dateEnvoi',
        align: 'center',
        field: function field(row) {
          return row.mail.dateEnvoi;
        },
        label: this.$t('dateEnvoi'),
        format: function format(val) {
          return date.formatDate(val, 'DD/MM/YYYY');
        }
      }, {
        name: 'dateOuverture',
        align: 'center',
        field: 'dateOuverture',
        label: this.$t('openingDate'),
        format: function format(val) {
          return date.formatDate(val, 'DD/MM/YYYY');
        }
      }, {
        name: 'nbRetry',
        field: 'nbRetry',
        label: this.$t('nbRetry'),
        format: function format(val) {
          return _this.$n(val);
        },
        align: 'center'
      }, {
        name: 'action'
      }]
    };
  },
  computed: getters,
  methods: actions
};