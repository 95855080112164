export var error = function error(state) {
  return state.error;
};
export var isLoading = function isLoading(state) {
  return state.isLoading;
};
export var retrieved = function retrieved(state) {
  return state.retrieved;
};
export default {
  error: error,
  isLoading: isLoading,
  retrieved: retrieved
};