import "core-js/modules/es6.object.keys.js";
import "core-js/modules/es6.symbol.js";
import "core-js/modules/es6.array.filter.js";
import "core-js/modules/es6.object.get-own-property-descriptor.js";
import "core-js/modules/es7.object.get-own-property-descriptors.js";
import _defineProperty from "/var/www/html/gui/node_modules/@babel/runtime-corejs2/helpers/defineProperty";
import "core-js/modules/es6.object.to-string.js";
import "core-js/modules/es6.array.iterator.js";
import "core-js/modules/web.dom.iterable.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { form } from "../../utils/vuexer";

var _form = form([{
  name: 'mail',
  module: 'Mail'
}]),
    getters = _form.getters,
    actions = _form.actions,
    mutations = _form.mutations;

export default {
  name: 'DestinataireForm',
  props: {
    values: {
      type: Object,
      required: true
    },
    errors: {
      type: Object,
      default: function _default() {}
    },
    initialValues: {
      type: Object,
      default: function _default() {}
    }
  },
  computed: _objectSpread(_objectSpread({}, getters), {}, {
    // eslint-disable-next-line
    item: function item() {
      return this.initialValues || this.values;
    },
    violations: function violations() {
      return this.errors || {};
    }
  }),
  methods: _objectSpread(_objectSpread(_objectSpread({}, actions), mutations), {}, {
    isInvalid: function isInvalid()
    /* key */
    {
      return true; // return val => {
      //   if (typeof val == 'number') {
      //     if (val > 0) {
      //       return true;
      //     } else {
      //       return this.$t('Please, insert a value bigger than zero!');
      //     }
      //   }
      //   if (!(val && val.length > 0)) return this.$t('Please type something');
      //   return Object.keys(this.violations).length === 0 && !this.violations[key];
      // };
    },
    mailFilterFn: function mailFilterFn(val, update
    /* , abort */
    ) {
      var _this = this;

      var params = {
        'exists[mail]': false
      };
      var template = JSON.stringify(params);
      return this.mailSelectItems !== null && this.mailSelectItemsTemplate === template ? update() : this.mailGetSelectItems({
        params: params
      }).then(function () {
        _this.mailSetSelectItemsTemplate(template);

        update();
      });
    }
  })
};