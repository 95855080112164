import SubmissionError from "../../../error/SubmissionError";
import fetch from "../../../utils/fetch";
export var resetCommon = function resetCommon(_ref, _ref2) {
  var commit = _ref.commit;
  var types = _ref2.types;
  commit(types.RESET);
};
export var retrieveCommon = function retrieveCommon(_ref3, id, _ref4) {
  var commit = _ref3.commit;
  var types = _ref4.types;
  commit(types.TOGGLE_LOADING);
  return fetch(id).then(function (response) {
    return response.json();
  }).then(function (data) {
    commit(types.TOGGLE_LOADING);
    commit(types.SET_RETRIEVED, data);
  }).catch(function (e) {
    commit(types.TOGGLE_LOADING);
    commit(types.SET_ERROR, e.message);
  });
};
export var updateCommon = function updateCommon(_ref5, values, _ref6) {
  var commit = _ref5.commit,
      state = _ref5.state;
  var types = _ref6.types;
  commit(types.SET_ERROR, '');
  commit(types.TOGGLE_LOADING);
  return fetch(state.retrieved && state.retrieved['@id'] || values['@id'], {
    method: 'PUT',
    headers: new Headers({
      'Content-Type': 'application/ld+json'
    }),
    body: JSON.stringify(values)
  }).then(function (response) {
    return response.json();
  }).then(function (data) {
    commit(types.TOGGLE_LOADING);
    commit(types.SET_UPDATED, data);
  }).catch(function (e) {
    commit(types.TOGGLE_LOADING);

    if (e instanceof SubmissionError) {
      commit(types.SET_VIOLATIONS, e.errors); // eslint-disable-next-line

      commit(types.SET_ERROR, e.errors._error);
      return;
    } // eslint-disable-next-line


    commit(types.SET_ERROR, e.message);
  });
};