//
//
//
//
import 'grapesjs/dist/css/grapes.min.css';

var grapesjs = require('grapesjs');

var nlPlugin = require('grapesjs-mjml'); // var ckPlugin = require('grapesjs-plugin-ckeditor')


grapesjs.plugins.add('gjs-preset-newsletter-2', nlPlugin); // grapesjs.plugins.add('gjs-plugin-ckeditor', ckPlugin)
// import CKEDITOR from 'ckeditor'

import fr from 'grapesjs/src/i18n/locale/fr.js';
export default {
  props: {
    value: {
      type: String
    }
  },
  data: function data() {
    return {
      editor: null
    };
  },
  mounted: function mounted() {
    this.initEditor();
  },
  methods: {
    updateValue: function updateValue(value) {
      this.$emit('mjml-change', value);
    },
    updateHtmlValue: function updateHtmlValue(value) {
      this.$emit('html-change', value);
    },
    initEditor: function initEditor() {
      var _this = this;

      var images = [];
      this.editor = grapesjs.init({
        clearOnRender: true,
        components: this.value,
        storageManager: {
          type: null
        },
        height: '800px',
        assetManager: {
          assets: images,
          upload: 0,
          uploadText: 'Uploading is not available in this demo'
        },
        i18n: {
          messages: {
            fr: fr
          }
        },
        container: '#gjs',
        plugins: ['gjs-preset-newsletter-2'],
        pluginsOpts: {
          /*  'gjs-plugin-ckeditor': {
              position: 'center',
              options: {
                startupFocus: true,
                extraAllowedContent: '*(*);*{*}', // Allows any class and any inline style
                allowedContent: true, // Disable auto-formatting, class removing, etc.
                enterMode: CKEDITOR.ENTER_BR,
                extraPlugins: 'sharedspace,justify,colorbutton,panelbutton,font',
                toolbar: [
                  { name: 'styles', items: ['Font', 'FontSize'] },
                  ['Bold', 'Italic', 'Underline', 'Strike'],
                  { name: 'paragraph', items: ['NumberedList', 'BulletedList'] },
                  { name: 'links', items: ['Link', 'Unlink'] },
                  { name: 'colors', items: ['TextColor', 'BGColor'] }
                ]
              }
            }
           */
        }
      }); // this.editor.keymaps.removeAll()

      var rte = this.editor.RichTextEditor;
      rte.add('link', {
        icon: 'L',
        attributes: {
          title: 'Lien'
        },
        // Example on it's easy to wrap a selected content
        result: function result(rte) {
          return rte.insertHTML("<a href=\"#\">".concat(rte.selection(), "</a>"));
        }
      });
      rte.add('indent', {
        icon: '&#8594;',
        attributes: {
          title: 'Indenter'
        },
        result: function result(rte) {
          return rte.exec('indent');
        }
      });
      rte.add('outdent', {
        icon: '&#8592;',
        attributes: {
          title: 'Désindenter'
        },
        result: function result(rte) {
          return rte.exec('outdent');
        }
      });
      rte.add('orderedList', {
        icon: '1.',
        attributes: {
          title: 'Liste ordonnée'
        },
        result: function result(rte) {
          return rte.exec('insertOrderedList');
        }
      });
      rte.add('unorderedList', {
        icon: '&#8226;',
        attributes: {
          title: 'Liste à puces'
        },
        result: function result(rte) {
          return rte.exec('insertUnorderedList');
        }
      });
      this.editor.on('update', function (some, argument) {
        var mjml = _this.editor.runCommand('mjml-get-code');

        _this.updateValue(_this.editor.getHtml());

        _this.updateHtmlValue(mjml.html);
      });
    }
  }
};