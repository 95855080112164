import _toConsumableArray from "/var/www/html/gui/node_modules/@babel/runtime-corejs2/helpers/toConsumableArray";
import mailRoutes from "./mail";
import mailtemplateRoutes from "./mailtemplate";
import destinataireRoutes from "./destinataire";
import unsubscribeRoutes from "./unsubscribeemail";
import emailerrorRoutes from "./emailerror";
var routes = [{
  path: '/',
  component: function component() {
    return import('layouts/MainLayout.vue');
  },
  children: [{
    path: '',
    component: function component() {
      return import('components/Index.vue');
    }
  }].concat(_toConsumableArray(mailtemplateRoutes), _toConsumableArray(mailRoutes), _toConsumableArray(destinataireRoutes), _toConsumableArray(unsubscribeRoutes), _toConsumableArray(emailerrorRoutes))
}]; // Always leave this as last one

if (process.env.MODE !== 'ssr') {
  routes.push({
    path: '*',
    component: function component() {
      return import('pages/Error404.vue');
    }
  });
}

export default routes;