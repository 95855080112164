//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'TestMail',
  data: function data() {
    return {
      email: ''
    };
  },
  props: {
    show: {
      type: Boolean,
      required: true
    },
    handleSendTest: {
      type: Function,
      required: true
    },
    handleCancel: {
      type: Function,
      required: false
    }
  }
};