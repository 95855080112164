import { types } from "./mutation_types";
import { delCommon, resetCommon } from "../../../../common/store/delete/actions";
export var del = function del(context, item) {
  return delCommon(context, item, {
    types: types
  });
};
export var reset = function reset(context) {
  resetCommon(context, {
    types: types
  });
};