// This is just an example,
// so you can safely delete all default props below
export default {
  failed: 'Action failed',
  success: 'Action was successful',
  Submit: 'Submit',
  Reset: 'Reset',
  Delete: 'Delete',
  Filters: 'Filters',
  Filter: 'Filter',
  'Records per page:': 'Records per page:',
  Add: 'Add',
  template: 'Template',
  data: 'Data',
  status: 'Status',
  totalToSend: 'Total to send',
  totalSended: 'Total sended',
  destinataires: 'Recipient(s)',
  destinataire: 'Recipient',
  email: 'Email',
  structure: 'Structure',
  code: 'Code',
  libelle: 'Wording',
  subject: 'Subject',
  senderName: 'Sender name',
  senderEmail: 'Sender email',
  texte: 'Text',
  mjml: 'MJML',
  mailToSend: 'Mail(s) to send',
  mailsSent: 'Mail(s) sent',
  progressOfMailsSent: 'Progress of mails sent',
  progress: 'Progress',
  general: 'General',
  content: 'Content',
  percent: 'Percentage of emails sent',
  stats: 'Statistics',
  dateUnsuscribe: 'Unsubscribe date',
  nbRetry: 'Number of retry',
  to: 'To',
  mail: 'Mail',
  mailsToSend: 'Mail(s) to send',
  dateEnvoi: 'Dispatch date',
  createdAt: 'Created at',
  toName: 'Name',
  toEmail: 'Email',
  menuHome: 'Home',
  menuMails: 'Mails',
  menuModeles: 'Models',
  menuDestinataires: 'Recipients',
  menuDesinscriptions: 'Unsubscriptions',
  cancel: 'Cancel',
  send: 'Send',
  UnsubscribeEmailList: 'Unsubscriptions list',
  EmailErrorList: 'Failed emails list',
  MailList: 'Mails list',
  MailTemplateList: 'Models list',
  DestinataireList: 'Recipients list',
  MailTemplateShow: 'Model',
  DestinataireShow: 'Recipient',
  MailShow: 'Mail',
  UnsubscribeEmailShow: 'Unsubscription',
  EmailErrorShow: 'Failed emails',
  delete: 'Delete',
  confirmDelete: 'Are you sure you want to delete this item?',
  MailUpdate: 'Edit the mail ',
  MailTemplateUpdate: 'Edit the template',
  DestinataireUpdate: 'Edit the recipient',
  UnsubscribeEmailUpdate: 'Edit the unsubscription',
  EmailErrorUpdate: 'Update the failed email',
  UnsubscribeEmailCreate: 'Create a unsubscription',
  DestinataireCreate: 'Create a recipient',
  MailTemplateCreate: 'Create a model',
  EmailErrorCreate: 'Create a failed email',
  aboutHermes: 'About Hermes',
  Updated: 'updated',
  Close: 'Close',
  Deleted: 'The element has been deleted',
  totalUnsubscribed: 'Total unsubscribed recipients',
  mailsUnsubscribed: 'Mail(s) unsubscribed',
  totalError: 'Total error mails',
  mailsError: 'Mail(s) in error',
  attachments: 'Actual attachment(s)',
  balisesMail: 'List of tags available in this mail',
  errors: 'Error(s)',
  unsubscribe: 'Unsubscribe(s)',
  html: 'HTML',
  openingDate: 'Opening date',
  listBalisesDest: 'List of recipient tags',
  listBalisesOther: 'List of other tags',
  openRate: 'Open rate',
  unsubscriptions: 'Do not send to unsusubscribed emails',
  doNotSend: 'Do not send',
  menuEmailsError: 'Failed emails',
  dateError: 'Error date'
};