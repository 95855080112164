import Notify from 'quasar/src/plugins/Notify.js';;

var error = function error(message, closeLabel) {
  return Notify.create({
    message: message,
    color: 'red',
    icon: 'error',
    closeBtn: closeLabel
  });
};

var success = function success(message, closeLabel) {
  return Notify.create({
    message: message,
    color: 'green',
    icon: 'tag_faces',
    closeBtn: closeLabel
  });
};

var warning = function warning(message, closeLabel) {
  return Notify.create({
    message: message,
    color: 'yellow',
    icon: 'warning',
    closeBtn: closeLabel
  });
};

export { error, success, warning };