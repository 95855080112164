//
//
//
//
//
//
//
//
//
//
import { create } from "../../utils/vuexer";
import EmailErrorForm from "./Form";
import { Breadcrumb, Toolbar, Loading } from "../../common/components";
import CreateMixin from "../../common/mixins/CreateMixin";
var servicePrefix = 'EmailError';

var _create = create(servicePrefix),
    getters = _create.getters,
    actions = _create.actions;

export default {
  name: 'EmailErrorCreate',
  servicePrefix: servicePrefix,
  mixins: [CreateMixin],
  components: {
    EmailErrorForm: EmailErrorForm,
    Breadcrumb: Breadcrumb,
    Toolbar: Toolbar,
    Loading: Loading
  },
  data: function data() {
    return {
      item: {
        dateError: new Date().toISOString()
      }
    };
  },
  computed: getters,
  methods: actions
};