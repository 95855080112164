//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { show } from "../../utils/vuexer";
import { Breadcrumb, Toolbar, Loading } from "../../common/components";
import ShowMixin from "../../common/mixins/ShowMixin";
import date from 'quasar/src/utils/date.js';;
var servicePrefix = 'EmailError';

var _show = show(servicePrefix),
    getters = _show.getters,
    actions = _show.actions;

export default {
  name: 'EmailErrorShow',
  servicePrefix: servicePrefix,
  mixins: [ShowMixin],
  components: {
    Breadcrumb: Breadcrumb,
    Toolbar: Toolbar,
    Loading: Loading
  },
  data: function data() {
    return {
      tab: 'email_errors',
      alert: false,
      columns: [{
        name: 'subject',
        label: this.$t('subject'),
        align: 'center',
        field: function field(row) {
          return row.subject;
        }
      }, {
        name: 'date',
        label: this.$t('date'),
        align: 'center',
        field: 'date',
        format: function format(val) {
          return date.formatDate(val, 'DD/MM/YYYY');
        }
      }, {
        name: 'content',
        label: this.$t('content'),
        field: '',
        align: 'center'
      }]
    };
  },
  computed: getters,
  methods: actions
};