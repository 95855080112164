import "core-js/modules/es6.object.keys.js";
import "core-js/modules/es6.symbol.js";
import "core-js/modules/es6.array.filter.js";
import "core-js/modules/es6.object.get-own-property-descriptor.js";
import "core-js/modules/es7.object.get-own-property-descriptors.js";
import _defineProperty from "/var/www/html/gui/node_modules/@babel/runtime-corejs2/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import MailList from "../components/mail/List";
import MailCreate from "../components/mail/Create";
import MailUpdate from "../components/mail/Update";
import MailShow from "../components/mail/Show";
var list = {
  label: 'MailList',
  icon: 'whatshot',
  name: 'MailList'
};
var create = {
  label: 'MailCreate',
  icon: 'whatshot',
  name: 'MailCreate'
};
var update = {
  label: 'MailUpdate',
  icon: 'whatshot',
  name: 'MailUpdate'
};
var show = {
  label: 'MailShow',
  icon: 'whatshot',
  name: 'MailShow'
};
export default [{
  name: 'MailList',
  path: '/mails/',
  component: MailList,
  meta: {
    breadcrumb: [list]
  }
}, {
  name: create.label,
  path: '/mails/create',
  component: MailCreate,
  meta: {
    breadcrumb: [_objectSpread(_objectSpread({}, list), {}, {
      to: {
        name: list.label
      }
    }), create]
  }
}, {
  name: update.label,
  path: '/mails/edit/:id',
  component: MailUpdate,
  meta: {
    breadcrumb: [_objectSpread(_objectSpread({}, list), {}, {
      to: {
        name: list.name
      }
    }), update]
  }
}, {
  name: 'MailShow',
  path: '/mails/show/:id',
  component: MailShow,
  meta: {
    breadcrumb: [_objectSpread(_objectSpread({}, list), {}, {
      to: {
        name: list.name
      }
    }), show]
  }
}];