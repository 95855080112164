import { error as _error } from "../../utils/notify";
export default {
  watch: {
    created: function created(_created) {
      if (_created) {
        this.onCreated(_created);
      }
    },
    error: function error(message) {
      message && _error(message, this.$t('Close'));
    }
  },
  methods: {
    onCreated: function onCreated(item) {
      this.$router.push({
        name: "".concat(this.$options.servicePrefix, "Update"),
        params: {
          id: item['@id']
        }
      });
    },
    onSendForm: function onSendForm() {
      var _this = this;

      this.$refs.createForm.$children[0].validate().then(function (success) {
        if (success) {
          _this.create(_this.item);
        }
      });
    },
    resetForm: function resetForm() {
      this.item = {};
    }
  }
};