import _defineProperty from "/var/www/html/gui/node_modules/@babel/runtime-corejs2/helpers/defineProperty";
import "core-js/modules/es6.object.assign.js";
export default (function (initState, types) {
  var _ref;

  return _ref = {}, _defineProperty(_ref, types.SET_DELETED, function (state, deleted) {
    Object.assign(state, {
      deleted: deleted
    });
  }), _defineProperty(_ref, types.SET_ERROR, function (state, error) {
    Object.assign(state, {
      error: error
    });
  }), _defineProperty(_ref, types.TOGGLE_LOADING, function (state) {
    Object.assign(state, {
      isLoading: !state.isLoading
    });
  }), _defineProperty(_ref, types.RESET, function (state) {
    Object.assign(state, initState);
  }), _ref;
});