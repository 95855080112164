/**
 * THIS FILE IS GENERATED AUTOMATICALLY.
 * DO NOT EDIT.
 *
 * You are probably looking on adding startup/initialization code.
 * Use "quasar new boot <name>" and add it there.
 * One boot file per concern. Then reference the file(s) in quasar.conf.js > boot:
 * boot: ['file', ...] // do not add ".js" extension to it.
 *
 * Boot files are your "main.js"
 **/
import lang from 'quasar/lang/en-us';
import iconSet from 'quasar/icon-set/material-icons';
import Vue from 'vue';
import Quasar from 'quasar/src/vue-plugin.js';import QTable from 'quasar/src/components/table/QTable.js';import QTh from 'quasar/src/components/table/QTh.js';import QTr from 'quasar/src/components/table/QTr.js';import QTd from 'quasar/src/components/table/QTd.js';import QBreadcrumbs from 'quasar/src/components/breadcrumbs/QBreadcrumbs.js';import QBreadcrumbsEl from 'quasar/src/components/breadcrumbs/QBreadcrumbsEl.js';import QSpace from 'quasar/src/components/space/QSpace.js';import QInput from 'quasar/src/components/input/QInput.js';import QForm from 'quasar/src/components/form/QForm.js';import QSelect from 'quasar/src/components/select/QSelect.js';import QMarkupTable from 'quasar/src/components/markup-table/QMarkupTable.js';import QDate from 'quasar/src/components/date/QDate.js';import QTime from 'quasar/src/components/time/QTime.js';import QCheckbox from 'quasar/src/components/checkbox/QCheckbox.js';import QPopupProxy from 'quasar/src/components/popup-proxy/QPopupProxy.js';import QSpinner from 'quasar/src/components/spinner/QSpinner.js';import QInnerLoading from 'quasar/src/components/inner-loading/QInnerLoading.js';import QCard from 'quasar/src/components/card/QCard.js';import QCardSection from 'quasar/src/components/card/QCardSection.js';import QCardActions from 'quasar/src/components/card/QCardActions.js';import QExpansionItem from 'quasar/src/components/expansion-item/QExpansionItem.js';import QDialog from 'quasar/src/components/dialog/QDialog.js';import QAvatar from 'quasar/src/components/avatar/QAvatar.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';import Notify from 'quasar/src/plugins/Notify.js';;
Vue.use(Quasar, {
  config: {},
  lang: lang,
  iconSet: iconSet,
  components: {
    QTable: QTable,
    QTh: QTh,
    QTr: QTr,
    QTd: QTd,
    QBreadcrumbs: QBreadcrumbs,
    QBreadcrumbsEl: QBreadcrumbsEl,
    QSpace: QSpace,
    QInput: QInput,
    QForm: QForm,
    QSelect: QSelect,
    QMarkupTable: QMarkupTable,
    QDate: QDate,
    QTime: QTime,
    QCheckbox: QCheckbox,
    QPopupProxy: QPopupProxy,
    QSpinner: QSpinner,
    QInnerLoading: QInnerLoading,
    QCard: QCard,
    QCardSection: QCardSection,
    QCardActions: QCardActions,
    QExpansionItem: QExpansionItem,
    QDialog: QDialog,
    QAvatar: QAvatar
  },
  directives: {
    ClosePopup: ClosePopup
  },
  plugins: {
    Notify: Notify
  }
});