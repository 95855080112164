import _defineProperty from "/var/www/html/gui/node_modules/@babel/runtime-corejs2/helpers/defineProperty";
import "core-js/modules/es6.array.map.js";
import "core-js/modules/es6.object.assign.js";
import "core-js/modules/es6.object.keys.js";
import "core-js/modules/es6.string.ends-with.js";
import SubmissionError from "../error/SubmissionError";
import { ENTRYPOINT } from "../config/entrypoint";
var MIME_TYPE = 'application/ld+json'; // make query string array of values

var makeParamArray = function makeParamArray(key, arr) {
  return arr.map(function (val) {
    return "".concat(key, "[]=").concat(val);
  }).join('&');
};

export default function (id) {
  var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  if (typeof options.headers === 'undefined') Object.assign(options, {
    headers: new Headers()
  });
  if (options.headers.get('Accept') === null) options.headers.set('Accept', MIME_TYPE);

  if (options.body !== undefined && !(options.body instanceof FormData) && options.headers.get('Content-Type') === null) {
    options.headers.set('Content-Type', MIME_TYPE);
  }

  if (options.params) {
    var queryString = Object.keys(options.params).map(function (key) {
      return Array.isArray(options.params[key]) ? makeParamArray(key, options.params[key]) : "".concat(key, "=").concat(options.params[key]);
    }).join('&');
    id = "".concat(id, "?").concat(queryString);
  } // enable CORS for all requests


  Object.assign(options, {
    mode: 'cors',
    credentials: 'include'
  });
  var entryPoint = ENTRYPOINT + (ENTRYPOINT.endsWith('/') ? '' : '/');
  return fetch(new URL(id, entryPoint), options).then(function (response) {
    if (response.ok) return response;
    return response.json().then(function (json) {
      var error = json['hydra:description'] ? json['hydra:description'] : response.statusText;
      if (!json.violations) throw Error(error);
      var errors = {
        _error: error
      };
      json.violations.map(function (violation) {
        return Object.assign(errors, _defineProperty({}, violation.propertyPath, violation.message));
      });
      throw new SubmissionError(errors);
    });
  });
}