//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { show } from "../../utils/vuexer";
import { Breadcrumb, Toolbar, Loading } from "../../common/components";
import ShowMixin from "../../common/mixins/ShowMixin";
var servicePrefix = 'UnsubscribeEmail';

var _show = show(servicePrefix),
    getters = _show.getters,
    actions = _show.actions;

export default {
  name: 'UnsubscribeEmailShow',
  servicePrefix: servicePrefix,
  mixins: [ShowMixin],
  components: {
    Breadcrumb: Breadcrumb,
    Toolbar: Toolbar,
    Loading: Loading
  },
  data: function data() {
    return {
      tab: 'unsubscribe'
    };
  },
  computed: getters,
  methods: actions
};