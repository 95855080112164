import { types } from "./mutation_types";
import { retrieveCommon, resetCommon } from "../../../../common/store/show/actions";
export var retrieve = function retrieve(context, id) {
  return retrieveCommon(context, id, {
    types: types
  });
};
export var reset = function reset(context) {
  resetCommon(context, {
    types: types
  });
};