import "core-js/modules/es6.object.keys.js";
import "core-js/modules/es6.symbol.js";
import "core-js/modules/es6.array.filter.js";
import "core-js/modules/es6.object.get-own-property-descriptor.js";
import "core-js/modules/es7.object.get-own-property-descriptors.js";
import _defineProperty from "/var/www/html/gui/node_modules/@babel/runtime-corejs2/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import { error as _error, success } from "../../utils/notify";
import { extractDate } from "../../utils/dates";
export default {
  created: function created() {
    this.onRequest({
      pagination: this.pagination
    });
  },
  data: function data() {
    return {
      pagination: {
        sortBy: null,
        descending: false,
        page: 1,
        // page to be displayed
        rowsPerPage: 30,
        // maximum displayed rows
        rowsNumber: 10 // max number of rows

      },
      nextPage: null,
      filters: {},
      filtration: {},
      expandedFilter: false
    };
  },
  watch: {
    error: function error(message) {
      message && _error(message, this.$t('Close'));
    },
    items: function items() {
      this.pagination.page = this.nextPage;
      this.pagination.rowsNumber = this.totalItems;
      this.nextPage = null;
    },
    deletedItem: function deletedItem(val) {
      success("".concat(this.$t('Deleted'), "."), this.$t('Close'));
    }
  },
  methods: {
    onRequest: function onRequest(props) {
      var _this = this;

      var _props$pagination = props.pagination,
          page = _props$pagination.page,
          itemsPerPage = _props$pagination.rowsPerPage,
          sortBy = _props$pagination.sortBy,
          descending = _props$pagination.descending;
      this.nextPage = page;

      var params = _objectSpread({}, this.filters);

      if (itemsPerPage > 0) {
        params = _objectSpread(_objectSpread({}, params), {}, {
          itemsPerPage: itemsPerPage,
          page: page
        });
      }

      if (sortBy) {
        params["order[".concat(sortBy, "]")] = descending ? 'DESC' : 'ASC';
      }

      this.getPage({
        params: params
      }).then(function () {
        _this.pagination.sortBy = sortBy;
        _this.pagination.descending = descending;
        _this.pagination.rowsPerPage = itemsPerPage;
      });
    },
    formatDateTime: function formatDateTime(val, format) {
      return val ? this.$d(extractDate(val), format) : '';
    },
    onSendFilter: function onSendFilter() {
      this.onRequest({
        pagination: this.pagination
      });
    },
    resetFilter: function resetFilter() {
      this.filters = {};
    },
    addHandler: function addHandler() {
      this.$router.push({
        name: "".concat(this.$options.servicePrefix, "Create")
      });
    },
    showHandler: function showHandler(item) {
      this.$router.push({
        name: "".concat(this.$options.servicePrefix, "Show"),
        params: {
          id: item['@id']
        }
      });
    },
    editHandler: function editHandler(item) {
      this.$router.push({
        name: "".concat(this.$options.servicePrefix, "Update"),
        params: {
          id: item['@id']
        }
      });
    },
    deleteHandler: function deleteHandler(item) {
      var _this2 = this;

      this.deleteItem(item).then(function () {
        return _this2.onRequest({
          pagination: _this2.pagination
        });
      });
    }
  }
};