import { types } from "./mutation_types";
import { createCommon, resetCommon } from "../../../../common/store/create/actions";
export var create = function create(context, values) {
  return createCommon(context, {
    page: 'mails',
    values: values
  }, {
    types: types
  });
};
export var reset = function reset(context) {
  resetCommon(context, {
    types: types
  });
};